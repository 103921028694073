<template>
  <div>
    <h1>我是欢迎页1</h1>

    <el-button type="primary" size="small" @click="test()">立即创建</el-button>
  </div>
</template>

<script>
import request from "@/utils/request"

const _import = require('@/router/_import') //获取组件的方法

export default {
  name: "Welcome",
  methods: {
    test() {
      return request({
        url: '/masterdata/resource/findResourceByUser/2',
        method: 'post',
        baseURL: 'http://127.0.0.1:8093',
        contentType: 'application/json',
      }).then((response) => {
        const data = response.data

        this.filterAsyncRouter(data)

        console.log(data)
      })
    },
    filterAsyncRouter(asyncRouterMap) {
      const accessedRouters = asyncRouterMap.filter(route => {
        if (route.component) {
          if (route.component === 'Layout') { //Layout组件特殊处理
            route.component = Layout
          } else {
            route.component = _import(route.component)
          }
        }
        if (route.children && route.children.length) {
          route.children = this.filterAsyncRouter(route.children)
        }
        return true
      })
      return accessedRouters
    }
  }
}
</script>

<style scoped>

</style>
